import styled from 'styled-components';

export const Document = styled.a`
  font-size: 20px;
  display: flex;
  width: fit-content;
  color: #007bff;
  text-decoration: underline !important;
  margin: 10px 0;
  text-transform: capitalize
`
