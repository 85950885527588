import React from "react"
import { graphql } from "gatsby";
import { Container, Box, Grid } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import CollapseIcon from '../components/CollapseIcon';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'
import { Document } from "../styles/pages/documentos";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function DocumentsPage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const diplomas = props.data.diplomas.edges

  return (
    <Layout>
      <SEO title="Diplomas registrados" />
      <Container style={{ marginTop: 50 }}>
        <PageTitle>Lista de diplomas registrados</PageTitle>
        <Grid container>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Diplomas registrados"
              icon="fa fa-folder"
              items={diplomas.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export const pagequery = graphql`
  query {
    editais: allFile(filter: {relativeDirectory: {eq: "Editais"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    ppcs: allFile(filter: {relativeDirectory: {eq: "PPCs"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     horarios2020: allFile(filter: {relativeDirectory: {eq: "Horarios-2020"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     horarios2021: allFile(filter: {relativeDirectory: {eq: "Horarios-2021"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     matrizes: allFile(filter: {relativeDirectory: {eq: "Matrizes"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     normas: allFile(filter: {relativeDirectory: {eq: "Normas-e-Regulamentos"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     pdi: allFile(filter: {relativeDirectory: {eq: "PDI-e-Edital"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     portarias: allFile(filter: {relativeDirectory: {eq: "Portarias"}}, sort: {fields: name, order: DESC}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     regimentos: allFile(filter: {relativeDirectory: {eq: "Regimentos"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     calendario: allFile(filter: {relativeDirectory: {eq: "Calendario-Academico"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    bolsas: allFile(filter: {relativeDirectory: {eq: "Bolsas"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    diplomas: allFile(filter: {relativeDirectory: {eq: "Diplomas"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`



