import React, { useState } from 'react'
import { Link } from 'gatsby'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons'
import Icon from '@material-ui/core/Icon';
import { StyledCollapse } from './styles'

export default function CollapseIcon(props) {
    const [open, setOpen] = useState(false)
    const { items } = props

    const handleClick = () => {
        setOpen(!open)
    }

    function ListItemLink(props) {
        return <ListItem button component={Link} {...props} />
    }

    return (
        <>
            <ListItem button onClick={handleClick}>
                {props.icon !== 'undefined' ? <Icon className={props.icon} style={{ marginRight: 5 }} /> : ''}
                <ListItemText primary={props.head} />
                {open ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            </ListItem>
            <StyledCollapse in={open} timeout="auto" unmountOnExit>
                <List>
                    {items.map(item => (
                        <ListItem component="a" href={item.to} target={item.type === 'external' ?
                            "_blank"
                            :
                            ''}
                        >
                            <Icon className={item.icon} style={{ marginRight: 5 }} />
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </StyledCollapse>
        </>
    )
}
